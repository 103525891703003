import { apiCall, createQueryString } from '../helper.js';

export function fetchQuestions(params) {
  return apiCall(
    `/admin/engagements/${params.engagementId}/questions/?${createQueryString({
      include_inputs: true,
    })}`
  );
}

export function fetchSections(params) {
  return apiCall(`/admin/engagements/${params.engagementId}/sections`);
}

export function repositionBlocks(id, body) {
  return apiCall(`/admin/engagements/${id}/reposition_blocks`, 'POST', JSON.stringify(body));
}

export function getMailgunStats(id) {
  return apiCall(`/admin/engagements/${id}/mailgun_stats`);
}
